
import { MsalProvider} from "@azure/msal-react";
import {JOYMNavbar} from './Navbar.js';
import {JOYMHome} from './Home.js';
import {Myjoym} from './myjoym.js';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";

import './App.css';

export default function App({ msalInstance }) {
  return (
    <MsalProvider instance={msalInstance}>
           
    <div className="App">
    <header>

      <div class="container">
        <JOYMNavbar />
      </div>
      
    </header>
          <UnauthenticatedTemplate>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<JOYMHome />}/>
              <Route path="/sign-up" element={" Sign-up"} />
              <Route path="/contact" element={" Contact"} />
              <Route path="/*" element={<JOYMHome />} />
            </Routes>
         

    </BrowserRouter>
    </UnauthenticatedTemplate>
          
          <AuthenticatedTemplate>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<JOYMHome />}/>
              <Route path="/sign-up" element={"Sign-up"} />
              <Route path="/contact" element={"Contact"} />
              <Route path="/myjoym" element={<Myjoym />} />
              <Route path="/*" element={<JOYMHome />} />
            </Routes>
         

    </BrowserRouter>
    </AuthenticatedTemplate>
   
    

     

    </div>
    </MsalProvider>

  );
}

