
import {Navbar} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";

const JOYMNavbar = () => {
   const { instance } = useMsal();
    const { accounts } = useMsal();
  return(


<nav class="navbar navbar-expand-lg navbar-light bg-light justify-content-between">
      <a class="navbar-brand" href="/">JOYM</a>
        
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
        </button>
        
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div class="navbar-nav">
            <a class="nav-item nav-link active" href="/">Home</a>
            <a class="nav-item nav-link" href="about">About</a>
            <a class="nav-item nav-link" href="sign-up">Sign-up</a>
          </div>
        </div>

          <div class="navbar-nav">
          
            <Button variant="primary" onClick={() => instance.loginRedirect(loginRequest)}>My JOYM</Button>
            
          </div>
      </nav>
    );

    };

    export {JOYMNavbar};