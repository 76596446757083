import React, { useState, useEffect } from "react";
import logo from './logo.svg';
import ReactDOM from "react-dom";
import './App.css';
import { useIsAuthenticated, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { useNavigate } from 'react-router-dom';
import { sign } from 'jwt-encode';


function signOutClickHandler(instance) {
  const logoutRequest = {
    postLogoutRedirectUri: "/",
  };
  instance.logoutRedirect(logoutRequest);
}


async function getText(instance, accounts)
{
	//Set up access Token Request Params
	const accessTokenRequest = {
		scopes: [],
      account: accounts[0],
    };

	//Get Token
	const loginToken =  await instance.acquireTokenSilent(accessTokenRequest);
  	const idToken = loginToken.idToken;
  	

  	//Make request
  	var result = "tests" 

  	var output = await fetch('https://joym-apim.azure-api.net/JOYM-Status/get_user_status?name=wob', {
   			method: 'post',
   			headers: new Headers({
   				'Ocp-Apim-Subscription-Key': '1a1cd2cde48c457ebf77da03bab77608',
   				'Authorization': idToken 
   			})
   		})
         .then((response) => { 
         	return response.text();})
     	.then((data) => {
     		result = data;
         })
         .catch((err) => {
            console.log(err.message);
         });
  return result;

};

const Myjoym = () => {

	const { instance } = useMsal();
    const { accounts } = useMsal();
	const [apiText, setApiText] = useState(accounts[0].idTokenClaims.given_name);
   
   
   useEffect(() => {
   	
   		getText(instance,accounts).then((token)=>{setApiText(token)});
     
     },[]);




	return (
		<div className="App">
                    <header className="App-header">
                        <p>
                            Hello ! {apiText}
                            </p>
                        <button onClick={() => signOutClickHandler(instance)}>
                            Logout
                        </button>
                    </header>
                </div>

		)
 
};
    export {Myjoym};
